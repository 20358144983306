import Cookies from 'js-cookie';

function cookieCheck() {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return false;
  }

  const allow = Cookies.get('h2-bp-analytics');

  if (allow === null || allow === undefined) {
    return false;
  }

  if (allow === 'true') {
    return true;
  }
}

export default cookieCheck;
