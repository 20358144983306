import React, { useState } from 'react';
import { Container, Col } from 'reactstrap';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import cookieCheck from '../../utils/cookieCheck';
import {
  cookieBannerCheck,
  setHideCookieBanner,
} from '../../utils/cookieBannerCheck';
import cookieActivate from '../../utils/cookieActivate';

function CookieBanner({
  title,
  description,
  allowLabel,
  preferencesLabel,
  rejectLabel,
}) {
  const currentLocation = useLocation();
  const cookiesAllowed = cookieCheck();
  const hideCookieBanner = cookieBannerCheck();
  const [hideBanner, setHideBanner] = useState(hideCookieBanner);

  if (cookiesAllowed) {
    initializeAndTrack(currentLocation);
  }

  const allowAll = () => {
    cookieActivate(currentLocation, initializeAndTrack);
    setHideCookieBanner();
    setHideBanner(true);
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      window.location.reload();
    }
  };

  const rejectAll = () => {
    setHideCookieBanner();
    setHideBanner(true);
  };

  if (hideBanner) {
    return <div />;
  }

  return (
    <div className="fixed-bottom mb-5" data-testid="cookie-banner">
      <Container>
        <div className="shadow-xl p-3 mb-5 bg-dark rounded">
          <div className="d-lg-flex align-items-center">
            <Col lg="6" className="mb-5 mt-5">
              <h2 className="mb-4">{title}</h2>
              <p className="font-family-headings font-weight-light opacity-60 mb-0">
                {description}
              </p>
            </Col>

            <Col
              lg="6"
              className="d-lg-flex flex-row align-items-center justify-content-center flex-wrap"
            >
              <button
                className="btn btn-lg btn-light rounded-pill d-block d-sm-inline-block mr-sm-4 mb-3 m-2 mb-sm-0"
                data-selector="cookie-banner-allow"
                onClick={allowAll}
              >
                {allowLabel}
              </button>
              <button
                className="btn btn-lg btn-light rounded-pill d-block d-sm-inline-block mr-sm-4 mb-3 m-2 mb-sm-0"
                data-selector="cookie-banner-allow"
                onClick={rejectAll}
              >
                {rejectLabel}
              </button>
              <Link
                to="/cookie-preferences"
                className="btn btn-lg btn-primary rounded-pill d-block m-2 d-sm-inline-block"
              >
                {preferencesLabel}
              </Link>
            </Col>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CookieBanner;
