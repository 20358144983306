import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container, Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';
import HyCC from '../../images/HyCC-bp-colour.svg';
import LanguageSelector from './LanguageSelector';
import HeaderNav from './HeaderNav';
import H2Fifty from '../../images/H2Fifty.png';

const Header = ({ siteTitle, englishButton, dutchButton }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <header className="navbar-fixed-top">
      <div>
        <Navbar color="light" light expand="xl" className="bg-white">
          <Container>
            <Link to="/" className="navbar-brand">
              <img src={H2Fifty} alt={siteTitle} style={{ width: '200px' }} />
            </Link>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mx-auto" navbar>
                <HeaderNav />
              </Nav>
              <LanguageSelector english={englishButton} dutch={dutchButton} />
            </Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
