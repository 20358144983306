import React, { useContext } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import LanguageContext from '../../context/Language';

function LanguageSelector({ english, dutch }) {
  const context = useContext(LanguageContext);
  const setUserLang = (lang) => {
    context.setLang(lang);
    localStorage.setItem('lang', lang);
  };

  return (
    <ButtonGroup size="md" className="btn-group-shadow rounded-pill">
      <Button
        className="rounded-pill"
        active={context.lang === 'en'}
        onClick={() => setUserLang('en')}
      >
        {english}
      </Button>
      <Button
        className="rounded-pill"
        active={context.lang === 'nl'}
        onClick={() => setUserLang('nl')}
      >
        {dutch}
      </Button>
    </ButtonGroup>
  );
}

export default LanguageSelector;
