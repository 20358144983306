import React from 'react';
import { Link } from 'gatsby';

const NavList = ({ menuItems }) => (
  <>
    <ul className="nav flex-column mb-7">
      {menuItems?.map((item) => (
        <li className="mb-3" key={item.id} data-testid={`nav-link-${item.id}`}>
          <Link to={item.link} className="link-border" activeClassName="active">
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  </>
);

export default NavList;
