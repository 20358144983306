import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';
//import LogoMono from '../../images/logo-mono.svg';
import HyCC from '../../images/HyCC-logo-white.png';
import BPP from '../../images/BPP_white.png';
import LanguageContext from '../../context/Language';
import NavList from './NavList';

const Footer = ({ copyrightNotice, siteTitle }) => {
  const context = useContext(LanguageContext);
  const data = useStaticQuery(graphql`
    query FooterNavQuery {
      en: allContentfulMenu(
        filter: { node_locale: { eq: "en-GB" } }
        sort: { fields: title }
      ) {
        nodes {
          label
          menuItems {
            title
            link
            id
          }
          id
          title
        }
      }
      nl: allContentfulMenu(
        filter: { node_locale: { eq: "nl-NL" } }
        sort: { fields: title }
      ) {
        nodes {
          label
          menuItems {
            title
            link
            id
          }
          id
          title
        }
      }
    }
  `);

  const copyYear = new Date().getFullYear().toString();
  const menus = data[context.lang];

  return (
    <footer className="section-padding font-family-headings font-weight-light bg-dark">
      <Container>
        <Row>
          <Col md="6" lg="2" className="mb-10">
            <img src={HyCC} alt={siteTitle} style={{ width: '82px' }} />
          </Col>
          <Col md="6" lg="1" className="mb-10">
            <img src={BPP} alt={siteTitle} style={{ width: '50px' }} />
          </Col>
          {menus.nodes.map((item) => (
            <Col md="3" lg="3" key={`footer-${item.id}`}>
              <NavList
                menuItems={item.menuItems}
                label={item.label}
                key={item.id}
              />
            </Col>
          ))}
        </Row>
        <div className="d-sm-flex align-items-center">
          <div
            className="mb-4 mb-sm-0 small opacity-50"
            data-testid="copyright"
          >
            &copy; {copyYear} {copyrightNotice}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
