import React, { useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { NavItem } from 'reactstrap';
import LanguageContext from '../../context/Language';

function HeaderNav() {
  const data = useStaticQuery(graphql`
    query HeaderNavQuery {
      en: contentfulMenu(
        contentful_id: { eq: "2XLlUeOWGd0vNO9TlshLex" }
        node_locale: { eq: "en-GB" }
      ) {
        menuItems {
          title
          link
          id
        }
      }
      nl: contentfulMenu(
        contentful_id: { eq: "2XLlUeOWGd0vNO9TlshLex" }
        node_locale: { eq: "nl-NL" }
      ) {
        menuItems {
          title
          link
          id
        }
      }
    }
  `);

  const context = useContext(LanguageContext);
  const menuItemsLocal = data[context.lang].menuItems;

  return (
    <>
      {menuItemsLocal.map((item) => (
        <NavItem key={`header-${item.id}`}>
          <Link to={item.link} className="nav-link" activeClassName="active">
            {item.title}
          </Link>
        </NavItem>
      ))}
    </>
  );
}

export default HeaderNav;
