import Cookies from 'js-cookie';

export function cookieBannerCheck() {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return false;
  }

  const allow = Cookies.get('hide-cookie-banner');

  if (allow === null || allow === undefined) {
    return false;
  }

  return allow === 'true' ? true : false;
}

export function setHideCookieBanner() {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return;
  }

  Cookies.set('hide-cookie-banner', 'true');
}
