import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import CookieBanner from '../components/CookieBanner/CookieBanner';
import '@bphxd/ds-core-react/lib/scss/bp-bootstrap.scss';
import '@bphxd/ds-core-react/lib/plugins/_plugins.scss';
import LanguageContext from '../context/Language';

const BaseLayout = ({ children, title, description }) => {
  const context = useContext(LanguageContext);
  const data = useStaticQuery(graphql`
    query BaseLayoutQuery {
      en: contentfulConfig(
        contentful_id: { eq: "2zgApuawYGybYuoY1SIqft" }
        node_locale: { eq: "en-GB" }
      ) {
        copyrightNotice
        englishLanguageButtonLabel
        dutchLanguageButtonLabel
        siteTitle
        cookieAllowLabel
        cookieRejectLabel
        cookieBannerDescription {
          cookieBannerDescription
        }
        cookieBannerTitle
        cookiePreferencesLabel
        globalSearchEngineHide
      }
      nl: contentfulConfig(
        contentful_id: { eq: "2zgApuawYGybYuoY1SIqft" }
        node_locale: { eq: "nl-NL" }
      ) {
        copyrightNotice
        englishLanguageButtonLabel
        dutchLanguageButtonLabel
        siteTitle
        cookieAllowLabel
        cookieRejectLabel
        cookieBannerDescription {
          cookieBannerDescription
        }
        cookieBannerTitle
        cookiePreferencesLabel
        globalSearchEngineHide
      }
    }
  `);

  const localData = data[context.lang];
  return (
    <>
      <Helmet>
        <html lang={context.lang} />
        <meta name="description" content={`${description}`} />
        <title>
          {title} - {localData.siteTitle}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {localData.globalSearchEngineHide && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <CookieBanner
        title={localData.cookieBannerTitle}
        description={localData.cookieBannerDescription.cookieBannerDescription}
        preferencesLabel={localData.cookiePreferencesLabel}
        allowLabel={localData.cookieAllowLabel}
        rejectLabel={localData.cookieRejectLabel}
      />
      <Header
        siteTitle={localData.siteTitle}
        englishButton={localData.englishLanguageButtonLabel}
        dutchButton={localData.dutchLanguageButtonLabel}
      />
      <main>{children}</main>
      <Footer
        copyrightNotice={localData.copyrightNotice}
        siteTitle={localData.siteTitle}
      />
    </>
  );
};

export default BaseLayout;
