import Cookies from 'js-cookie';

function cookieActivate(currentLocation, init) {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) {
    return;
  }

  if (!currentLocation || !init) {
    return;
  }

  Cookies.set('h2-bp-analytics', 'true');
  init(currentLocation);
}

export default cookieActivate;
